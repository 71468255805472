import { API } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import DocopaHeader from "../components/DocopaHeader.js";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  TextField,
  Container,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  IconButton,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";

function AdminMessage({ getUserApiResult, userEmail }) {
  const navigate = useNavigate();

  // フォームの状態を管理
  const [message, setMessage] = useState("");
  const [category, setCategory] = useState("ALL");
  const [emailList, setEmailList] = useState([]);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(true);

  const handleCategoryChange = (value) => {
    setCategory(value);

    if (value === "USER") {
      setEmailList([""]);
      setEmailError(true);
    } else {
      setEmailList([]);
      setEmailError(false);
    }
  };

  // メールアドレスを追加
  const handleAddEmail = () => {
    setEmailList([...emailList, ""]);
  };

  // メールアドレスを更新
  const handleEmailChange = (index, value) => {
    const newEmailList = [...emailList];
    newEmailList[index] = value;
    setEmailList(newEmailList);

    // 空のメールアドレスが1つでもある場合はエラー
    setEmailError(newEmailList.some((email) => email.trim() === ""));
  };

  // メールアドレスを削除
  const handleRemoveEmail = (index) => {
    const newEmailList = emailList.filter((_, i) => i !== index);
    setEmailList(newEmailList);

    // 空のメールアドレスが1つでもある場合はエラー
    setEmailError(newEmailList.some((email) => email.trim() === ""));
  };

  // 送信ボタン押下時の処理
  const handleSubmit = async () => {
    if (
      category === "USER" &&
      emailList.every((email) => email.trim() === "")
    ) {
      setEmailError(true);
      return;
    }

    // 確認ポップアップ
    const confirmSend = window.confirm(
      `以下の内容で送信しますか？\n\n送信先区分: ${
        category === "USER" ? "個人" : "全ユーザー"
      }\n` +
        (category === "USER" ? `送信先: ${emailList.join(", ")}\n` : "") +
        `メッセージ: ${message}`,
    );

    if (!confirmSend) {
      return; // キャンセルされた場合は処理中断
    }

    try {
      const targetEmails = category === "USER" ? emailList : [];

      const requestData = {
        adminNotificationItem: {
          targetAttribute: category,
          targetEmail: targetEmails,
          message: message,
          notificationSource: userEmail,
        },
      };

      await API.graphql({
        query: mutations.createAdminNotification,
        variables: requestData,
      });

      alert("通知が送信されました！");
      navigate("/manage");
    } catch (error) {
      console.error("送信エラー:", error);
      alert("通知の送信に失敗しました。");
    }
  };

  return (
    <>
      <DocopaHeader
        isManagePath={true}
        cursor={true}
        handleLogoClick={() => navigate(`/manage`)}
        getUserApiResult={getUserApiResult}
      />
      <Container maxWidth="md">
        <Box sx={{ mt: 4, p: 3, border: "1px solid #ccc", borderRadius: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            管理者通知配信
          </Typography>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <FormHelperText>メッセージの送信区分を選択</FormHelperText>
            <Select
              value={category}
              onChange={(e) => handleCategoryChange(e.target.value)}
              displayEmpty
            >
              <MenuItem value="" disabled>
                送信先選択
              </MenuItem>
              <MenuItem value="USER">個人</MenuItem>
              <MenuItem value="ALL">全ユーザー</MenuItem>
            </Select>
          </FormControl>

          {category === "USER" && (
            <Box sx={{ mb: 2 }}>
              {emailList.map((email, index) => (
                <Box
                  key={index}
                  sx={{ display: "flex", alignItems: "center", mb: 1 }}
                >
                  <TextField
                    label={`メールアドレス ${index + 1}`}
                    type="email"
                    value={email}
                    onChange={(e) => handleEmailChange(index, e.target.value)}
                    variant="outlined"
                    fullWidth
                    error={emailError && email.trim() === ""}
                  />
                  <IconButton
                    onClick={() => handleRemoveEmail(index)}
                    color="error"
                  >
                    <Delete />
                  </IconButton>
                </Box>
              ))}
              <Button
                variant="outlined"
                startIcon={<Add />}
                onClick={handleAddEmail}
              >
                メールアドレスを追加
              </Button>

              {emailError && (
                <FormHelperText sx={{ color: "red" }}>
                  メールアドレスを入力してください
                </FormHelperText>
              )}
            </Box>
          )}

          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              label="メッセージ"
              multiline
              rows={4}
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
                setMessageError(e.target.value.trim() === "");
              }}
              variant="outlined"
              error={messageError}
            />
            {messageError && (
              <FormHelperText sx={{ color: "red" }}>
                メッセージを入力してください
              </FormHelperText>
            )}
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              category === "" ||
              (category === "USER" &&
                (emailList.length === 0 ||
                  emailList.some((email) => email.trim() === ""))) ||
              message.trim() === ""
            }
          >
            送信
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default AdminMessage;
