import {
  TextField,
  Container,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useState, useEffect, useCallback } from "react";

import { useNavigate } from "react-router-dom";
// ドコパヘッダーコンポーネント
import DocopaHeader from "../components/DocopaHeader.js";
import { checkReq, explanText } from "../variables/ManageRelated.js";
import { useForm } from "react-hook-form";
import { styled } from "@mui/system";

import DeleteIcon from "@mui/icons-material/Delete";
import { Backdrop, CircularProgress } from "@mui/material";
// AppSync呼び出し用
import { API } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

const StyleHelpText = styled("span")(({ theme }) => ({
  "& a": {
    color: theme.palette.primary.main,
    textDecoration: "underline",
  },
}));

function CommonForm({
  onSubmit,
  initVal,
  state,
  setThumbnailDel,
  getUserApiResult,
}) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
    control,
    setValue,
  } = useForm();

  const descriptionMax = 2000;
  const videoFileMax = 4800 * 1024 * 1024; // 5GB弱;
  // 動画ファイルプレビュー
  const [videoUrl, setvideoUrl] = useState(null);
  // 更新の動画ファイルプレビュー
  const [videoImgUrl, setvideoImgUrl] = useState(null);
  const [videoText, setVideoText] = useState(null);
  // 画像ファイルプレビュー
  const [imageUrl, setImageUrl] = useState(null);
  const [imageText, setImageText] = useState(null);
  // 更新画面: 既存サムネイルある場合 true : 削除ボタン表示
  const [imageDelBtn, setImageDelBtn] = useState(false);
  // 更新画面: 削除ボタンクリック/ サムネイルへ別ファイル選択し true
  const [imageDelFlg, setImageDelFlg] = useState(false);
  const [submitText, setSubmitText] = useState(null);
  // getTagListリクエスト
  const [data, setData] = useState([]);
  // 削除中確認
  const [isDeleting, setIsDeleting] = useState(false);
  // 確認ダイアログの状態
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // 結果ダイアログの状態（完了・エラー共通）
  const [resultDialog, setResultDialog] = useState({
    open: false,
    title: "",
    message: "",
    isError: false,
  });

  // 登録・更新の動画・サムネイルのヘルパーテキスト/submitボタンの文言設定
  useEffect(() => {
    setVideoText(
      state === "edit"
        ? explanText.video.EditHelperText
        : explanText.video.helperText,
    );
    setImageText(
      state === "edit"
        ? explanText.thumbnail.EditHelperText
        : explanText.thumbnail.helperText,
    );
    setSubmitText(state === "edit" ? "更新" : "登録");
    setImageDelBtn(state === "edit" && !!initVal.setting_thumbnail_url);
    // eslint-disable-next-line
  }, []);

  // 動画ファイル検知
  const videoWatch = watch("video");
  useEffect(() => {
    clearErrors("video");
    if (videoWatch && videoWatch.length > 0) {
      state === "edit" && setValue("descriptions", "");
      setvideoUrl(URL.createObjectURL(videoWatch[0]));
      setvideoImgUrl(null);
      // 動画ファイルではない
      if (!videoWatch[0]?.type.startsWith("video/")) {
        setvideoUrl(null);
        setvideoImgUrl(null);
        setError("video", {
          message: explanText.video.otherFileErrText,
        });
      }
      // 動画サイズ確認
      if (videoWatch[0]?.size >= videoFileMax) {
        setvideoUrl(null);
        setvideoImgUrl(null);
        setError("video", {
          message: "FileSizeOver",
        });
      }
    } else {
      // キャンセルボタンクリック処理
      setvideoUrl(state === "edit" ? initVal.video_url : null);
      setvideoImgUrl(state === "edit" ? initVal.thumbnail_url : null);
    }
    // eslint-disable-next-line
  }, [videoWatch, clearErrors, setError]);

  // サムネイルファイル検知
  const thumbnailWatch = watch("thumbnail");
  useEffect(() => {
    clearErrors("thumbnail");

    if (thumbnailWatch && thumbnailWatch.length > 0) {
      setImageDelBtn(false); // 別ファイルを選択すると、削除ボタンなくす
      setImageDelFlg(true);
      setImageUrl(URL.createObjectURL(thumbnailWatch[0]));
      // 画像ファイルではない
      if (!thumbnailWatch[0]?.type.startsWith("image/")) {
        setImageUrl(null);
        setError("thumbnail", {
          message: explanText.thumbnail.errText,
        });
      }
    } else {
      // キャンセルボタンクリック処理
      setImageUrl(
        state === "edit" && !imageDelFlg ? initVal.setting_thumbnail_url : null,
      );
    }
    // eslint-disable-next-line
  }, [thumbnailWatch, clearErrors, setError]);

  // 動画概要検知
  const descriptionWatch = watch("descriptions");
  useEffect(() => {
    clearErrors("descriptions");
    if (descriptionWatch && descriptionWatch.length > descriptionMax) {
      setError("descriptions");
    }
  }, [descriptionWatch, clearErrors, setError]);

  // サブタグ検知
  const subTagsWatch = watch("subTags");
  useEffect(() => {
    clearErrors("subTags");
    if (subTagsWatch) {
      const subTagsValue = subTagsWatch.split(",").map((tag) => tag.trim());
      // サブタグの最大件数確認
      if (subTagsValue.length > 5) {
        setError("subTags", { message: explanText.subTags.errText });
      } else {
        // サブタグの長さ検知(15文字まで)
        for (const tag of subTagsValue) {
          if (tag.length > 15) {
            setError("subTags", {
              message: explanText.subTags.errMaxTagLength,
            });
          }
        }
      }
    }
  }, [subTagsWatch, clearErrors, setError]);

  // 確認ダイアログを開く
  const handleOpenConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(true);
  }, []);

  // 確認ダイアログを閉じる
  const handleCloseConfirmDialog = useCallback(() => {
    setOpenConfirmDialog(false);
  }, []);

  // 結果ダイアログを閉じて必要に応じてナビゲーションする関数
  const handleCloseResultDialog = useCallback(() => {
    setResultDialog((prev) => ({ ...prev, open: false }));
    // エラーでない場合（完了時）のみ遷移
    if (!resultDialog.isError) {
      navigate("/manage");
    }
  }, [resultDialog.isError, navigate]);

  // 削除処理の関数を修正
  const handleDeleteVideo = useCallback(async () => {
    setIsDeleting(true);
    setOpenConfirmDialog(false);

    try {
      const numericVideoId = parseInt(initVal.id, 10);

      await API.graphql({
        query: mutations.deleteVideo,
        variables: {
          id: numericVideoId,
          register_date: initVal.register_date,
        },
      });

      setIsDeleting(false);
      // 完了ダイアログを表示
      setResultDialog({
        open: true,
        title: "完了",
        message: "動画の削除が完了しました。",
        isError: false,
      });
    } catch (error) {
      console.error("動画削除エラー:", error);
      setIsDeleting(false);
      // エラーダイアログを表示
      setResultDialog({
        open: true,
        title: "エラー",
        message: "動画削除に失敗しました。再度お試しください。",
        isError: true,
      });
    }
  }, [initVal.id, initVal.register_date]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const tagListResponse = await API.graphql({
          query: queries.getTagList,
        });

        // データをidでソート
        const sortedData = tagListResponse.data.getTagList.data.sort(
          (a, b) => a.id - b.id,
        );

        setData(sortedData);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <DocopaHeader
        isManagePath={true}
        cursor={true}
        handleLogoClick={() => navigate(`/manage`)}
        getUserApiResult={getUserApiResult}
      />
      {data && data.length > 0 ? (
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="タイトル"
                color={checkReq.required.color}
                text={checkReq.required.text}
              />
              <TextField
                name="title"
                fullWidth
                margin="normal"
                variant="outlined"
                helperText={
                  errors.title
                    ? errors.title.message
                    : explanText.title.helperText
                }
                error={!!errors.title}
                {...register("title", {
                  required: explanText.title.errText,
                })}
                defaultValue={initVal.title}
              />
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="カテゴリ"
                color={checkReq.required.color}
                text={checkReq.required.text}
              />
              {
                <FormControl
                  style={{ width: "80%", marginLeft: "7px", marginTop: "7px" }}
                >
                  <Select
                    name="tag"
                    control={control}
                    defaultValue={initVal.tag ? initVal.tag : ""}
                    variant="outlined"
                    {...register("tag", {
                      required: explanText.tag.errText,
                    })}
                    onChange={() => clearErrors("tag")}
                    error={!!errors.tag}
                  >
                    {data.map((item, index) => (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!errors.tag}>
                    {errors.tag ? (
                      errors.tag.message
                    ) : (
                      <StyleHelpText>
                        動画のカテゴリを選択してください。(カテゴリを追加したい場合はSlackの「
                        <a
                          href="https://slb-sl.slack.com/archives/C060M0FKG06"
                          target="_blank"
                          rel="noreferrer"
                        >
                          #docopa_おすすめ動画周知
                        </a>
                        」チャンネルまでお問い合わせ下さい。)
                      </StyleHelpText>
                    )}
                  </FormHelperText>
                </FormControl>
              }
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="タグ"
                color={checkReq.elective.color}
                text={checkReq.elective.text}
              />
              <TextField
                name="subTags"
                fullWidth
                margin="normal"
                variant="outlined"
                helperText={
                  errors.subTags
                    ? errors.subTags.message
                    : explanText.subTags.helperText
                }
                error={!!errors.subTags}
                {...register("subTags", {})}
                defaultValue={initVal.subtags}
              />
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="動画概要"
                color={checkReq.elective.color}
                text={checkReq.elective.text}
              />
              <TextField
                name="descriptions"
                id="outlined-multiline-static"
                multiline
                rows={4}
                helperText={
                  <>
                    <Typography variant="caption">
                      {`動画の説明文を入力してください。（${
                        descriptionWatch ? descriptionWatch.length : 0
                      } / ${descriptionMax}文字）`}
                    </Typography>
                    <br />
                    <Typography variant="caption">
                      未入力の場合、AIにより自動生成された概要が表示されます。
                    </Typography>
                  </>
                }
                error={!!errors.descriptions}
                {...register("descriptions", {
                  maxLength: descriptionMax,
                })}
                defaultValue={initVal.description}
              />
            </Box>
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="動画ファイル"
                color={checkReq.required.color}
                text={checkReq.required.text}
              />
              <div style={{ width: 550, float: "left" }}>
                <TextField
                  name="video"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type="file"
                  inputProps={{
                    accept: "video/*",
                  }}
                  helperText={
                    errors.video ? (
                      <StyleHelpText>
                        {errors.video.message === "FileSizeOver" ? (
                          <>
                            ※5GBを超える動画ファイルはこちらのページからはアップロードできません。(アップロードをご希望の場合は、Slackの「
                            <a
                              href="https://slb-sl.slack.com/archives/C060M0FKG06"
                              target="_blank"
                              rel="noreferrer"
                            >
                              #docopa_おすすめ動画周知
                            </a>
                            」チャンネルまでお問い合わせ下さい。
                          </>
                        ) : (
                          errors.video.message
                        )}
                      </StyleHelpText>
                    ) : (
                      videoText
                    )
                  }
                  error={!!errors.video}
                  {...register("video", {
                    required:
                      state === "edit" ? false : explanText.video.nonErrText,
                    validate: {
                      acceptedFormats: (files) => {
                        if (files[0] && !files[0]?.type.startsWith("video/")) {
                          return explanText.video.otherFileErrText;
                        }
                      },
                      moreThan5GB: (files) => {
                        if (files[0] && files[0]?.size >= videoFileMax) {
                          return "FileSizeOver";
                        }
                      },
                    },
                  })}
                />
              </div>
              {!videoUrl ? (
                <NonFileBox />
              ) : (
                <video
                  src={videoUrl}
                  alt="アップロード画像"
                  poster={videoImgUrl}
                  style={{
                    width: "350px",
                    minHeight: "200px",
                    marginTop: "0.5rem",
                  }}
                />
              )}
            </Box>
            <div style={{ clear: "both" }} />
            <Box
              sx={{
                "& .MuiTextField-root": { m: 1, width: "80%" },
              }}
            >
              <TitleLine
                title="サムネイル"
                color={checkReq.elective.color}
                text={checkReq.elective.text}
              />
              <div style={{ dispay: "flex", alignItems: "center" }}>
                <div style={{ width: 550, float: "left" }}>
                  <TextField
                    name="thumbnail"
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    type="file"
                    inputProps={{
                      accept: "image/*",
                    }}
                    helperText={
                      errors.thumbnail ? errors.thumbnail.message : imageText
                    }
                    error={!!errors.thumbnail}
                    {...register("thumbnail", {
                      validate: {
                        acceptedFormats: (files) => {
                          if (
                            files[0] &&
                            !files[0]?.type.startsWith("image/")
                          ) {
                            return explanText.thumbnail.errText;
                          }
                        },
                      },
                    })}
                  />
                  {imageDelBtn && (
                    <Button
                      variant="contained"
                      sx={{ marginLeft: "10px" }}
                      onClick={() => {
                        setImageUrl(null);
                        setImageDelBtn(false);
                        setImageDelFlg(true);
                        setThumbnailDel(true);
                      }}
                    >
                      <DeleteIcon />
                      表紙画像の削除
                    </Button>
                  )}
                </div>
                {!imageUrl ? (
                  <NonFileBox />
                ) : (
                  <img
                    src={imageUrl}
                    alt="アップロード画像"
                    style={{
                      width: "350px",
                      minHeight: "200px",
                      marginTop: "0.5rem",
                    }}
                  />
                )}
              </div>
            </Box>
            <div style={{ clear: "both" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between", // 両端に配置
                alignItems: "center",
                my: "30px",
              }}
            >
              <Box>
                {/* 左側のボタングループ */}
                <Button
                  variant="contained"
                  sx={{ width: "100px", mx: "auto", my: "30px" }}
                  type="submit"
                  disabled={
                    !!errors.title ||
                    !!errors.tag ||
                    !!errors.subTags ||
                    !!errors.descriptions ||
                    !!errors.video ||
                    !!errors.thumbnail
                  }
                >
                  {submitText}
                </Button>
                <Button
                  variant="outlined"
                  sx={{ width: "100px", marginLeft: "5px" }}
                  onClick={() => navigate(`/manage`)}
                >
                  戻る
                </Button>
              </Box>
              {/* 削除ボタン */}
              {state === "edit" && (
                <Button
                  variant="outlined"
                  color="error"
                  sx={{ width: "100px" }}
                  onClick={handleOpenConfirmDialog}
                >
                  削除
                </Button>
              )}
            </Box>
          </form>
          {/* 削除確認用バックドロップ */}
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme) => theme.zIndex.drawer + 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            open={isDeleting}
          >
            <CircularProgress color="inherit" />
            <Typography variant="h6" sx={{ marginTop: 2 }}>
              動画を削除中...
            </Typography>
          </Backdrop>
          {/* 削除確認ダイアログ */}
          <Dialog
            open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            aria-labelledby="confirm-dialog-title"
          >
            <DialogTitle id="confirm-dialog-title">動画の削除確認</DialogTitle>
            <DialogContent>
              <DialogContentText>
                この動画を削除してもよろしいですか？
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirmDialog} color="primary">
                キャンセル
              </Button>
              <Button onClick={handleDeleteVideo} color="error" autoFocus>
                削除する
              </Button>
            </DialogActions>
          </Dialog>

          {/* 結果ダイアログ（完了・エラー共通） */}
          <Dialog
            open={resultDialog.open}
            onClose={handleCloseResultDialog}
            aria-labelledby="result-dialog-title"
          >
            <DialogTitle
              id="result-dialog-title"
              sx={{ color: resultDialog.isError ? "error.main" : "inherit" }}
            >
              {resultDialog.title}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{resultDialog.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseResultDialog}
                color="primary"
                autoFocus
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      ) : null}
    </>
  );
}
/**
 * @param title　ラベルテキスト
 * @param color　必須・任意のbgカラー
 * @param text 必須・任意
 * @returns 入力ラベル・必須・任意バッチ
 */
const TitleLine = ({ title, color, text }) => {
  return (
    <>
      <Typography
        sx={{
          width: "10%",
          float: "left",
          marginTop: "1.75rem",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: "4%",
          float: "left",
          marginTop: "1.9rem",
          backgroundColor: color,
          color: "white",
          borderRadius: "5px",
          textAlign: "center",
          fontSize: "12px",
        }}
      >
        {text}
      </Typography>
    </>
  );
};

/** ビデオ・サムネイル未選択の場合表示 */
const NonFileBox = () => {
  return (
    <div
      style={{
        width: "350px",
        minHeight: "200px",
        float: "left",
        borderRadius: "5px",
        background: "#EEEEEE",
        marginTop: "0.5rem",
      }}
    >
      <p style={{ textAlign: "center" }}>プレビュー</p>
    </div>
  );
};

export default CommonForm;
