import { useState, useEffect } from "react";
import { Chip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { styled } from "@mui/material/styles";

// ビデオカードリストコンポーネント
import VideoCardList from "../components/VideoCardList.js";

import { useLocation } from "react-router-dom";

//APIリクエスト
import { API, graphqlOperation } from "@aws-amplify/api";
import * as queries from "../graphql/queries";

/**
 * ドコモのトップ・検索結果
 */
function Top() {
  // getSearchListリクエスト
  const [data, setData] = useState([]);
  // getSearchListリクエスト長さ取得
  const [dataLength, setDataLength] = useState(0);
  // 画面フラグ top : 0 / tag : 1 / 検索 : 2
  const [viewFlg, setViewFlg] = useState(0);
  // Navigateで設定した引数を受け取るzw
  const location = useLocation();
  const state = location.state;
  // 動画再生画面でのサブタグクリックされた場合
  const searchSubtag =
    state && Object.keys(state).length === 1 && "subtag" in state;

  useEffect(() => {
    // データ取得⇒検索フラグを同期的に行う
    const fetchData = async () => {
      try {
        // サブタグ検索かどうかで呼ぶAPI、レスポンスの構造を変える
        const query = searchSubtag
          ? queries.getSubTagVideo
          : queries.getSearchList;

        let allVideos = [];
        let nextToken = null;

        do {
          const response = await API.graphql(
            graphqlOperation(query, {
              ...state,
              nextToken: nextToken,
              limit: 200,
            }),
          );

          const responseData = searchSubtag
            ? response.data.getSubTagVideo
            : response.data.getSearchList;

          // videoListを直接使用せず、responseから取得したvideo_listを使用
          allVideos = [...allVideos, ...responseData.video_list];
          nextToken = responseData.nextToken;
        } while (nextToken); // nextTokenがnullになるまで繰り返し

        setData(allVideos);
        setDataLength(allVideos.length);
      } catch (e) {
        console.error(e);
      }

      // データ取得後にフラグを設定
      setViewFlg(state ? 2 : 0);
    };

    fetchData();
  }, [state, searchSubtag]);

  const TagGrid = styled(Grid)({
    display: "flex",
    flexDirection: "row",
  });
  const TagExpText = styled(Typography)({
    fontSize: "24px",
    marginBottom: "15px",
  });
  return (
    <>
      {searchSubtag && (
        <TagGrid container spacing={2}>
          <Grid>
            <TagExpText>絞り込み条件：</TagExpText>
          </Grid>
          <Grid>
            <TagExpText label={`#${state.subtag}`} component={Chip} />
          </Grid>
          <Grid>
            <TagExpText>全件：{dataLength}件</TagExpText>
          </Grid>
        </TagGrid>
      )}
      {<VideoCardList data={data} viewFlg={viewFlg} dataLength={dataLength} />}
      {
        // 検索結果0件の場合
        data.length === 0 && viewFlg === 2 && (
          <div>
            <h2>検索結果がありません。</h2>
          </div>
        )
      }
    </>
  );
}
export default Top;
