import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import { Typography, Grid, Box, Tooltip, useMediaQuery } from "@mui/material";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Chip from "@mui/material/Chip";
import SubTagsForCard from "../components/SubTagsForCard.js";

import Button from "@mui/material/Button";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { API } from "@aws-amplify/api";
//import { Auth } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

import Linkify from "linkify-react";

function Playmovie({ getUserApiResult }) {
  // 動画情報取得APIの変数宣言
  const [infodata, infosetData] = useState([]);
  const [tagdata, setTagData] = useState([]); // getSearchListAPIの変数宣言
  const { id } = useParams();
  const idNumber = parseInt(id, 10); // URLパラメーターの取得及び型の変換
  const [firstPlay, setFirstPlay] = useState(true); // APIコールの状態を管理
  const [liked, setLiked] = useState(false); // いいねしているかどうか
  const [likeDisabled, setLikeDisabled] = useState(false); //いいねボタンの有効・無効
  const [likeCount, setLikeCount] = useState(0);
  const [userInfo, setUserInfo] = useState(""); //ユーザ情報
  const theme = responsiveFontSizes(createTheme()); // 動画タイトルレスポンシブ対応用
  const isLargeScreen = useMediaQuery("(min-width:1100px)");
  let navigate = useNavigate();

  // countVideoAPIのコール関数
  const countVideo = async () => {
    if (firstPlay) {
      try {
        // API呼び出し
        await API.graphql({
          query: mutations.countVideo,
          variables: {
            id: idNumber,
            register_date: infodata.register_date,
          },
        });

        await API.graphql({
          query: mutations.registerViewHistory,
          variables: {
            input: {
              email: getUserApiResult.email,
              video_id: idNumber, // Int型
            },
          },
        });

        // GTMのdataLayerにイベントをプッシュ
        if (window.dataLayer) {
          window.dataLayer.push({
            event: "video_play",
            videoId: idNumber,
            videoTitle: infodata?.title,
            videoCategory: infodata?.tag,
          });
          console.log("GA4 event sent: video_play");
        }
      } catch (error) {
        console.error("Error:", error);
      }
      setFirstPlay(false);
    }
  };

  const [loading, setLoading] = useState(true); // 初期状態をtrue（ローディング状態）に設定
  const [notFound, setNotFound] = useState(false); //無効な動画の場合trueにする変数
  useEffect(() => {
    const fetchData = async () => {
      try {
        // playMovie クエリの実行
        const infoPlaymovieResponse = await API.graphql({
          query: queries.playMovie,
          variables: { id: idNumber },
        });
        const playMovieData = infoPlaymovieResponse.data.playMovie.method[0];
        infosetData(playMovieData);
        setLikeCount(playMovieData?.likes);

        // tag が存在する場合、getSearchList クエリの実行
        if (playMovieData && playMovieData.tag) {
          let allVideos = [];
          let nextToken = null;

          do {
            const searchVariables = {
              tag: playMovieData.tag,
              ...(nextToken && { nextToken }),
              limit: 200, // 1回のリクエストで取得する最大件数
            };

            const tagResponse = await API.graphql({
              query: queries.getSearchList,
              variables: searchVariables,
            });

            const response = tagResponse.data.getSearchList;
            // videoListを直接使用せず、responseから取得したvideo_listを使用
            allVideos = [...allVideos, ...response.video_list];
            nextToken = response.nextToken;
          } while (nextToken); // nextTokenがnullになるまで繰り返し

          setTagData(allVideos);
        }
        if (!playMovieData || playMovieData.status === "DELETED") {
          //存在しない動画や削除された動画の場合リダイレクト用フラグを設定
          setNotFound(true);
        } else {
          // 有効な動画の場合、ローディング状態をfalseに更新
          setLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    const fetchUserData = async () => {
      const variables = {
        email: getUserApiResult.email,
      };
      //ユーザ情報取得
      const userInformationResponse = await API.graphql({
        query: queries.getUserInformation,
        variables: variables,
      });
      setUserInfo(userInformationResponse.data.getUserInformation.userData);
      const userLiked =
        userInformationResponse.data.getUserInformation.userData
          .liked_videoid_list === null
          ? false
          : userInformationResponse.data.getUserInformation.userData.liked_videoid_list.includes(
              idNumber,
            );
      setLiked(userLiked);
    };
    fetchUserData();
    fetchData();
  }, [idNumber, getUserApiResult]);

  // 無効な動画の場合notFoundページにリダイレクト
  // マイページからの遷移等でブラウザの戻るボタンが機能しなくなるので、replace:true追加
  if (notFound) {
    navigate(`/notfound`, { replace: true });
  }
  // データ取得が完了するまではローディングを表示
  if (loading) {
    return null;
  }

  // countUpLikeAPIのコール関数追加
  const countUpLike = async () => {
    setLiked(!liked);
    setLikeDisabled(true);
    const variables = {
      id: infodata.id,
      email: userInfo.email,
      register_date: infodata.register_date,
    };
    try {
      await API.graphql({
        query: mutations.countUpLike,
        variables: variables,
      }).then(setLikeDisabled(false));
      const changeLikeCount = liked ? -1 : 1;
      setLikeCount(Number(likeCount) + changeLikeCount);
      // APIのレスポンスを処理する部分
    } catch (error) {
      // エラーハンドリング
      console.error("Error:", error);
      setLiked(liked);
    }
  };

  const baseConfig = {
    url: infodata?.video_url,
    controls: true,
    width: "100%",
    height: "100%",
    playsinline: true,
    onPlay: countVideo,
    config: {
      file: {
        attributes: {
          controlsList: "nodownload", // ダウンロードボタンを無効にする
        },
      },
    },
  };

  if (infodata?.setting_thumbnail_url) {
    baseConfig.config.file.attributes.poster = infodata.setting_thumbnail_url;
  } else {
    baseConfig.config.file.attributes.poster = infodata.thumbnail_url;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        justify="center"
        spacing={isLargeScreen ? 2 : 0}
        sx={{
          maxWidth: "87vw",
        }}
      >
        <Grid
          item
          xs={12} // スマホ時には全画面を使う設定
          sm={8} // タブレット以上では横幅を8/12に設定
          sx={{
            "@media (min-width: 600px)": {
              xs: 8, // PCでは横幅を8/12に
            },
          }}
        >
          <Box
            sx={{
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              "@media (max-width: 600px)": {
                width: "100%", // スマホでは全画面幅
                maxWidth: "100%", // スマホでは制限なし
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            <ReactPlayer
              {...baseConfig}
              width="100%" // ReactPlayer の横幅を100%に設定
              height="auto" // アスペクト比を保持しつつ自動調整
            />
          </Box>
          <Box
            sx={{
              width: "100%", // 全画面幅に設定
              maxWidth: "700px", // デスクトップでは最大700px
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // 中央配置
              padding: "15px",
              paddingLeft: "0px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography variant="h6">{infodata?.title}</Typography>
            </ThemeProvider>
          </Box>
          <Box
            sx={{
              maxWidth: "700px",
              flexDirection: "column",
              justifyContent: "center", // 中央配置
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: "#757575", fontSize: "14px", fontWeight: "bold" }}
            >
              更新日：{infodata?.update_date.split(" ", 1)}　再生数：
              {infodata?.views}　カテゴリ：
              <Link
                to={"/tagvideolist"}
                state={{ tagName: infodata?.tag }}
                target="_self"
                rel="noopener noreferrer"
              >
                {infodata?.tag}
              </Link>
            </Typography>
            <Button
              variant="outlined"
              startIcon={<ThumbUpIcon color={liked ? "primary" : "action"} />}
              onClick={countUpLike}
              disabled={likeDisabled}
              sx={{ margin: "10px" }}
            >
              {likeCount}
            </Button>
            <Grid
              item
              style={{
                maxWidth: "700px",
              }}
            >
              {infodata?.subtags &&
                infodata?.subtags.map((tag, i) => (
                  <Chip
                    label={`#${tag}`}
                    size="small"
                    variant="outlined"
                    key={i}
                    style={{
                      margin: "3px",
                    }}
                    onClick={() => navigate(`/top`, { state: { subtag: tag } })}
                  />
                ))}
            </Grid>
          </Box>
          <Box
            sx={{
              maxWidth: "700px",
              flexDirection: "column",
              justifyContent: "center", // 中央配置
              padding: "8px",
              bgcolor: "grey.200",
              borderRadius: "8px",
              marginTop: "5px",
              marginBottom: "10px",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: "16px",
                whiteSpace: "pre-line",
                wordBreak: "break-word",
              }}
            >
              {infodata?.description[0]?.length ? (
                <Linkify>{infodata.description[0]}</Linkify>
              ) : (
                "只今AIによって動画概要を生成中です。しばらくお待ちください。"
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12} // スマホ時には全画面を使う設定
          sm={4} // タブレット以上では横幅を4/12に設定
          sx={{
            display: "flex",
            flexDirection: "column",
            "@media (min-width: 1100px)": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
            },

            "@media (max-width: 1100px)": {
              display: "none", // 横幅が1100未満の場合、非表示にする
            },
            "@media (max-width: 600px)": {
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            },
          }}
        >
          {tagdata?.map((tagItem, index) =>
            idNumber !== tagItem.id ? (
              <Tooltip
                placement="bottom-start"
                variant="outlined"
                title={tagItem.description}
                followCursor
                arrow
                key={index}
                disableHoverListener={!tagItem.description[0].length > 0}
              >
                <Card
                  sx={{
                    maxWidth: 270, // デフォルトのmaxWidth
                    marginBottom: 3,
                    "@media (max-width: 600px)": {
                      maxWidth: "100%", // 600px以下ではmaxWidthを100%
                    },
                  }}
                  onClick={() => {
                    setLikeDisabled(false);
                    navigate(`/playmovie/${tagItem.id}`, { replace: true });
                  }}
                >
                  <CardActionArea>
                    <div style={{ position: "relative" }}>
                      <CardMedia
                        component="img"
                        sx={{
                          height: "auto",
                          "@media (max-width: 600px)": {
                            width: "100%", // 600px以下ではwidthを100%
                          },
                        }}
                        image={
                          tagItem.setting_thumbnail_url
                            ? tagItem.setting_thumbnail_url
                            : tagItem.thumbnail_url
                        }
                        alt="green iguana"
                      />
                      <Typography
                        sx={{
                          color: "#fff",
                          backgroundColor: "rgba(0, 0, 0, 0.7)",
                          position: "absolute",
                          bottom: 5,
                          right: 5,
                          borderRadius: 1,
                          p: 0.5,
                          fontSize: "0.65rem",
                          lineHeight: "0.6rem",
                        }}
                      >
                        {tagItem.video_time}
                      </Typography>
                    </div>
                    <CardContent
                      sx={{
                        height: 70,
                        padding: "3px",
                        paddingLeft: "7px",
                        paddingRight: "7px",
                      }}
                    >
                      <Typography
                        gutterBottom
                        variant="subtitle2"
                        component="div"
                        sx={{
                          overflow: "hidden",
                          display: "-webkit-box",
                          WebkitLineClamp: "3",
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {tagItem.title}
                      </Typography>
                    </CardContent>
                    <CardContent
                      sx={{ height: 55, padding: "3px", paddingLeft: "7px" }}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography variant="body2" color="text.secondary">
                          更新日: {tagItem.update_date.split(" ", 1)}
                        </Typography>
                        <Box ml={2}>
                          <Typography variant="body2" color="text.secondary">
                            再生数: {tagItem.views}
                          </Typography>
                        </Box>
                        <Box ml={2} display="flex" alignItems="center">
                          <ThumbUpIcon
                            fontSize="inherit"
                            sx={{ color: theme.palette.text.secondary }}
                          />
                          <Typography variant="body2" color="text.secondary">
                            &nbsp;{tagItem.likes}
                          </Typography>
                        </Box>
                      </Box>
                      {tagItem.subtags && (
                        <SubTagsForCard subtags={tagItem.subtags} />
                      )}
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Tooltip>
            ) : (
              <Tooltip></Tooltip>
            ),
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Playmovie;
