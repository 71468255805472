import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { red, common, blueGrey } from "@mui/material/colors";
import { useState, useEffect } from "react";
import { API } from "@aws-amplify/api";
import * as queries from "../graphql/queries";
import VideoCardList from "../components/VideoCardList.js";
import { useNavigate } from "react-router-dom";
import Linkify from "linkify-react";

// 削除された動画用のサムネイルファイル
import ThumbnailDeletedImage from "../assets/deleted.png";

function MyPage({ getUserApiResult }) {
  const [userInformation, setUserInformation] = useState({});
  const [viewHistoryInformation, setViewHistoryInformation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [readMore, setReadMore] = useState(true);
  const [likedVideos, setLikedVideos] = useState([]);
  const [viewHistoryVideos, setViewHistoryVideos] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [iconUrls, setIconUrls] = useState(() =>
    notifications.map((n) => ({ iconURL: null, notificationId: n.id })),
  );
  const [notificationsvideos, setNotificationsvideos] = useState([]);

  const [selectedTab, setSelectedTab] = useState(0);
  const [refresh, setRefresh] = useState(false);
  // userIsAdminの状態を管理
  const [userIsAdmin, setUserIsAdmin] = useState(false);

  const extraContent = (
    <span onClick={() => setReadMore(!readMore)} className="read-more">
      ...さらに表示
    </span>
  );

  const lessContent = (
    <span onClick={() => setReadMore(!readMore)} className="read-less">
      ...閉じる
    </span>
  );

  const navigate = useNavigate();

  // .mp4 をリンク化させないための関数
  const preventMp4Links = (text) => {
    // .mp4 部分をそのまま表示し、それ以外をリンク化する
    return text.split(" ").map((part, index) => {
      if (part.match(/\S+\.mp4$/)) {
        // .mp4 を含む部分はそのまま表示
        return <span key={index}>{part}</span>;
      } else {
        // .mp4 以外はリンク化
        return <Linkify key={index}>{part}</Linkify>;
      }
    });
  };
  const NotificationMessage = ({ notification }) => {
    return <div>{preventMp4Links(notification.message)}</div>;
  };

  // userIsAdminの確認を行うuseEffect
  useEffect(() => {
    if (getUserApiResult && getUserApiResult.is_admin !== null) {
      setUserIsAdmin(getUserApiResult.is_admin);
    }
  }, [getUserApiResult]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const variables = {
          email: getUserApiResult.email,
        };
        const userInformationResponse = await API.graphql({
          query: queries.getUserInformation,
          variables: variables,
        });
        setUserInformation(
          userInformationResponse.data.getUserInformation.userData,
        );
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const historyFetchData = async () => {
      try {
        const variables = {
          email: getUserApiResult.email,
        };
        const viewHistoryResponse = await API.graphql({
          query: queries.getViewHistory,
          variables: variables,
        });
        setViewHistoryInformation(
          viewHistoryResponse.data.getViewHistory.viewHistory,
        );
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const notificationFetchData = async () => {
      try {
        const variables = { email: getUserApiResult.email };
        const getNotificationResponse = await API.graphql({
          query: queries.getNotification,
          variables: variables,
        });

        setNotifications(
          getNotificationResponse.data.getNotification.notification_list,
        );
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
    historyFetchData();
    notificationFetchData();
  }, [getUserApiResult, refresh]); // fetchDataは一度だけ実行する

  useEffect(() => {
    const fetchNotificateIconData = async () => {
      try {
        const notificationUserIcons = await Promise.all(
          notifications.map(async (notification) => {
            const notificationEmail = notification.notification_source;

            if (!notificationEmail) {
              return { iconURL: null, notificationId: notification.id };
            }

            try {
              const userInformationResponse = await API.graphql({
                query: queries.getUserInformation,
                variables: { email: notificationEmail },
              });

              if (
                userInformationResponse.data.getUserInformation.resultCode ===
                2000
              ) {
                return {
                  iconURL:
                    userInformationResponse.data.getUserInformation.userData
                      .profile_picture,
                  notificationId: notification.id,
                };
              }
            } catch (error) {
              console.error("User Info Fetch Error:", error);
            }
            return { iconURL: null, notificationId: notification.id };
          }),
        );

        setIconUrls(notificationUserIcons);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchVideoData = async () => {
      try {
        const videos = await Promise.all(
          notifications.map(async (notification) => {
            const videoId = notification.video_id;
            if (!videoId) {
              return { video: null, notificationId: notification.id };
            }

            try {
              const infoPlaymovieResponse = await API.graphql({
                query: queries.playMovie,
                variables: { id: videoId },
              });

              return {
                video: infoPlaymovieResponse.data.playMovie.method[0],
                notificationId: notification.id,
              };
            } catch (error) {
              console.error("Video Fetch Error:", error);
              return { video: null, notificationId: notification.id };
            }
          }),
        );

        setNotificationsvideos(videos);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchNotificateIconData();
    fetchVideoData();
  }, [notifications]);

  useEffect(() => {
    const fetchLikedVideoInfo = async () => {
      let tempArray = [];
      const likedVideoidList = userInformation.liked_videoid_list;
      if (likedVideoidList && likedVideoidList.length > 0) {
        await API.graphql({
          query: queries.getVideoByIdList,
          variables: { idList: likedVideoidList },
        }).then((result) => {
          const videoList = result.data.getVideoByIdList.video_list;
          for (const video of videoList) {
            if (video.status === "COMPLETE") {
              tempArray.push(video);
            } else if (video.status === "DELETED") {
              tempArray.push({
                ...video,
                setting_thumbnail_url: "Thumbnail_DELETED",
              });
            }
          }
        });
      }
      setLikedVideos(tempArray);
      setLoading(false);
    };

    if (userInformation.liked_videoid_list) {
      fetchLikedVideoInfo();
    }
  }, [userInformation]); // userInformationが更新された時にfetchLikedVideoInfoを実行

  // viewHistoryInformationの変更を監視し、変更があった場合にfetchViewHistoryInfoを実行
  useEffect(() => {
    const fetchViewHistoryInfo = async () => {
      let viewHistoryArray = [];
      if (viewHistoryInformation && viewHistoryInformation.length > 0) {
        await API.graphql({
          query: queries.getVideoByIdList,
          variables: { idList: viewHistoryInformation },
        }).then((result) => {
          const videoList = result.data.getVideoByIdList.video_list;
          for (const video of videoList) {
            if (video.status === "COMPLETE") {
              viewHistoryArray.push(video);
            } else if (video.status === "DELETED") {
              viewHistoryArray.push({
                ...video,
                setting_thumbnail_url: "Thumbnail_DELETED",
              });
            }
          }
        });
      }
      setViewHistoryVideos(viewHistoryArray);
      setLoading(false);
    };

    if (viewHistoryInformation && viewHistoryInformation.length > 0) {
      fetchViewHistoryInfo();
    }
  }, [viewHistoryInformation]);

  if (loading) {
    return <div></div>;
  }

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center" // 縦方向も中央揃え
        sx={{
          width: "100%", // 幅を画面サイズに合わせる
          maxWidth: "87vw", // 横幅を画面内に収める
          overflowX: "hidden", // 横スクロールを防止
          overflowY: "hidden", // 横スクロールを防止
          boxSizing: "border-box", // レイアウト崩れ防止
        }}
      >
        {/* 管理画面へのボタンを追加 */}
        {userIsAdmin && (
          <Grid item sx={{ width: "100%", mb: 2 }}>
            {/* mb: 2 でボタンとカードの間にマージンを設定 */}
            <Button
              variant="contained"
              onClick={() => navigate("/manage")}
              sx={{
                backgroundColor: "primary.main",
                "&:hover": {
                  backgroundColor: "primary.dark",
                },
              }}
            >
              DOCOPA管理画面
            </Button>
          </Grid>
        )}
        <Grid item sx={{ width: "100%", boxSizing: "border-box" }}>
          <Card
            sx={{
              width: "100%",
              maxWidth: "100%", // 最大幅を設定して余白を調整
              margin: "0 auto", // 中央寄せ
              bgcolor: blueGrey[50],
              boxSizing: "border-box",
            }}
          >
            <CardContent
              sx={{
                boxSizing: "border-box",
                "&:last-child": {
                  paddingBottom: 2, // 最後の子要素へのスタイルも上書き
                },
              }}
            >
              <Box
                sx={{
                  width: "100%", // 親要素に合わせて幅を設定
                  display: "flex", // 横並びにする
                  alignItems: "center", // 垂直方向を中央揃え
                  justifyContent: "flex-start",
                  gap: 2, // アイテム間の余白を設定
                }}
              >
                <Avatar
                  alt="User Image"
                  src={userInformation.profile_picture}
                  sx={{ width: 100, height: 100 }}
                />
                <Typography
                  sx={{
                    fontSize: {
                      xs: "1.5rem", // スマホ画面用
                      md: "2.1rem", // PC用
                    },
                    wordBreak: "break-word", // 長い単語を折り返す
                  }}
                >
                  {userInformation.user_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%", // 親要素に合わせて幅を設定
                  display: "flex", // 横並びにする
                  alignItems: "center", // 垂直方向を中央揃え
                  justifyContent: "flex-start",
                  paddingTop: 1, // 上側のPaddingを追加（必要に応じて調整）
                }}
              >
                <Typography
                  color="text.primary"
                  sx={{
                    fontSize: {
                      xs: "1.1rem", // スマホ画面用
                      md: "1.5rem", // PC用
                    },
                  }}
                >
                  プロフィール
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%", // 親要素に合わせて幅を設定
                  display: "flex", // 横並びにする
                  alignItems: "center", // 垂直方向を中央揃え
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  color="text.primary"
                  sx={{
                    fontSize: {
                      xs: "0.8rem", // スマホ画面用
                      md: "1.1rem", // PC用
                    },
                  }}
                >
                  {userInformation.profile_text
                    ? readMore
                      ? userInformation.profile_text.length > 100
                        ? userInformation.profile_text.substring(0, 100)
                        : userInformation.profile_text
                      : userInformation.profile_text
                    : ""}
                  {userInformation.profile_text &&
                    userInformation.profile_text.length > 100 &&
                    (readMore ? extraContent : lessContent)}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* タブ部分 */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="お知らせ" sx={{ fontSize: "1.0rem", fontWeight: "bold" }} />
        <Tab
          label="いいねした動画"
          sx={{ fontSize: "1.0rem", fontWeight: "bold" }}
        />
        <Tab
          label="視聴した動画"
          sx={{ fontSize: "1.0rem", fontWeight: "bold" }}
        />
      </Tabs>
      {selectedTab === 0 &&
        notifications.map((notification, index) => (
          <div key={notification.id}>
            <Typography
              sx={{
                color: "red",
                fontSize: {
                  xs: "0.8rem", // スマホ画面用
                  md: "1.1rem", // PC用
                },
                wordBreak: "break-word", // 長い単語を折り返す
              }}
            >
              {notification.notification_date}
            </Typography>
            <Card
              sx={{
                width: "100%",
                maxWidth: "100%", // 最大幅を設定して余白を調整
                //margin: "0 auto", // 中央寄せ
                bgcolor:
                  notification.notification_attribute === "ADMIN"
                    ? red[50]
                    : common[50],
                boxSizing: "border-box",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                  width: "100%", // 親要素に合わせて幅を設定
                  display: "flex", // 横並びにする
                  alignItems: "center", // 垂直方向を中央揃え
                  justifyContent: "flex-start",
                  gap: 2, // アイテム間の余白を設定
                }}
              >
                {iconUrls.filter((item) => {
                  return item.notificationId === notification.id;
                }) ? (
                  iconUrls
                    .filter((item) => {
                      return item.notificationId === notification.id;
                    })
                    .map((item) => {
                      return (
                        <Avatar
                          key={item.notificationId}
                          alt="User Image"
                          src={item.iconURL}
                          sx={{ width: 30, height: 30 }}
                        />
                      );
                    })
                ) : (
                  <Avatar
                    alt="User Image"
                    src={null}
                    sx={{ width: 30, height: 30 }}
                  />
                )}

                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: {
                      xs: "1.1rem", // スマホ画面用
                      md: "1.4rem", // PC用
                    },
                    wordBreak: "break-word", // 長い単語を折り返す
                  }}
                >
                  {notification.notification_attribute === "SYSTEM"
                    ? "システムメッセージ"
                    : notification.notification_attribute === "ADMIN"
                      ? "管理者メッセージ"
                      : notification.notification_source}
                </Typography>
              </Box>
              <Stack direction={"row"}>
                <Box
                  sx={{
                    width: "80%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex", // 横並びにする
                      alignItems: "center", // 垂直方向を中央揃え
                      justifyContent: "flex-start",
                      paddingTop: 2, // 上側のPaddingを追加（必要に応じて調整）
                      paddingLeft: "60px",
                      "@media (max-width: 600px)": {
                        paddingLeft: "10px",
                      },
                      wordBreak: "break-word", // 文章の折り返しの設定
                      whiteSpace: "pre-line", // 改行コードを有効にする
                    }}
                  >
                    <Typography
                      color="text.primary"
                      sx={{
                        fontSize: {
                          xs: "0.8rem", // スマホ画面用
                          md: "1.1rem", // PC用
                        },
                      }}
                    >
                      <NotificationMessage notification={notification} />
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  {notification.video_id &&
                    notificationsvideos.filter((item) => {
                      return item.notificationId === notification.id;
                    }) &&
                    notificationsvideos
                      .filter((item) => {
                        return item.notificationId === notification.id;
                      })
                      .map((item) => {
                        return (
                          item.video && (
                            <img
                              key={notification.id}
                              src={
                                item.video.status === "DELETED"
                                  ? ThumbnailDeletedImage
                                  : item.video.setting_thumbnail_url ||
                                    item.video.thumbnail_url
                              }
                              alt="Thumbnail"
                              style={{
                                maxHeight: "100px",
                              }}
                            />
                          )
                        );
                      })}
                </Box>
              </Stack>
              <CardContent
                sx={{
                  boxSizing: "border-box",
                  "&:last-child": {
                    paddingBottom: 1, // 最後の子要素へのスタイルも上書き
                  },
                }}
              ></CardContent>
            </Card>
            <br />
          </div>
        ))}
      {selectedTab === 1 && (
        <div>
          {likedVideos.length === 0 ? (
            <Typography variant="h5" style={{ margin: "1rem 0" }}>
              いいねした動画はありません
            </Typography>
          ) : (
            <VideoCardList
              data={likedVideos}
              viewFlg={3}
              lastFlg=""
              showHeartButton={true}
              userEmail={userInformation.email}
              refreshLikedVideo={() => setRefresh(!refresh)}
              dataLength={likedVideos.length}
            />
          )}
        </div>
      )}
      {selectedTab === 2 && (
        <div>
          {viewHistoryVideos.length === 0 ? (
            <Typography variant="h5" style={{ margin: "1rem 0" }}>
              視聴した動画はありません
            </Typography>
          ) : (
            <VideoCardList
              data={viewHistoryVideos}
              viewFlg={4}
              lastFlg=""
              showHeartButton={false}
              userEmail={userInformation.email}
              refreshLikedVideo={() => setRefresh(!refresh)}
              dataLength={viewHistoryVideos.length}
            />
          )}
        </div>
      )}
    </>
  );
}
export default MyPage;
